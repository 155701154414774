/*================================
            Home
================================*/
.home {
  padding-top: 12px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Montserrat;

    .name {
      display: flex;
      align-items: center;
    }

    .name img {
      margin-right: 12px;
      width: 80px;
      border-radius: 50px;
    }

    .nav-about a {
      padding: 16px;
    }

    .nav-about a:hover {
      background: #F2F2F2;
      border-radius: 4px;
    }

    h1 {
      font-size: 30px;
      line-height: 1.2;
      display: inline-block;
    }
  }

  p {
    max-width: 800px;
    line-height: 1.6;
  }
}

/* Smartphone view 📱*/
@media (max-width : 576px) {
  .home {
    .header {

      .name img {
        width: 66px;
      }
  
      h1 {
        font-size: 22px;
      }
  
      a {
        font-size: 18px;
      }
    }

  }
}
