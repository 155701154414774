/*================================
              Posts
================================*/
.posts {
  .post {
    margin: 15px 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    text-decoration: none;
    transition: .3s;
    border: 1px solid #eee;

    .date {
      display: block;
      font-size: 16px;
    }
  }

  .post:hover {
    transform: translateY(-3px);
    color: #2D84FF;
  }
}
