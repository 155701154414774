/*================================
          Single Post
================================*/
.post {
  margin-bottom: 60px;

  .header-intro {
    h2 {
      margin-top: 45px;
    }
    h4 {
      margin-top: 0;
    }
  }

  h2 {
    margin-top: 60px;
    margin-bottom: 5px;
  }

  p {
    margin-top: 0;
    margin-bottom: 30px;
  }

  small {
    margin-top: 16px;
    margin-bottom: 32px;
    display: block;
    text-align: center;
  }

  .sub-intro {
    display: flex;

    div {
      margin-right: 32px;

      h3 {
        font-size: 18px;
        margin-bottom: 0;
        border-bottom: 1px solid #eee;
      }

      p {
        margin: 8px 0;
        line-height: 1;
        font-size: 16px;
      }
    }
  }
}

/* Smartphone view 📱*/
@media only screen and (max-width : 700px) {
  .post {
    .header-intro {
      h4 {
        font-size: 18px;
      }
    }
  }

  .sub-intro {
    flex-direction: column;
  }

}
