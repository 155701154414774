@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body{margin:0 auto;max-width:1400px;font-family:'Roboto', sans-serif;font-size:20px;color:#252525;background:#FAFAFA;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}nav{font-family:'Montserrat', sans-serif}.main-wrapper{position:relative;min-height:100vh}main{padding:12px;line-height:1.5}h1,h2,h3,h4{font-weight:bold;font-family:'Montserrat', sans-serif}h1{font-size:36px}h2{font-size:26px}h3{font-size:22px}h4{margin-bottom:16px}a{text-decoration:none;transition:.3s all;color:#2D84FF}a:hover{color:#0067F9}ul{margin-top:16px;line-height:1.8}p{font-size:20px;line-height:1.8}img{width:100%}.content img{margin:16px 0 4px}.block{margin-bottom:24px;display:block;height:4px;width:40px;background:#2D84FF;border-radius:4px}.button{background-color:#2D84FF;color:#fff;padding:16px 32px;border-radius:4px;border-bottom:4px solid #0067F9;text-decoration:none}.button:hover{opacity:.9;color:#fff;box-shadow:0px 10px 40px -10px rgba(0,64,128,0.2)}.max-1200{margin:0 auto;max-width:1200px}.max-1000{margin:0 auto;max-width:1000px}.max-800{margin:0 auto;max-width:800px}.animate{-webkit-animation-duration:.7s;animation-duration:.7s;-webkit-animation-fill-mode:both;animation-fill-mode:both}@-webkit-keyframes fadeIn{from{opacity:0;-webkit-transform:translate3d(0, 4%, 0);transform:translate3d(0, 4%, 0)}to{opacity:1;-webkit-transform:none;transform:none}}@keyframes fadeIn{from{opacity:0;-webkit-transform:translate3d(0, 4%, 0);transform:translate3d(0, 4%, 0)}to{opacity:1;-webkit-transform:none;transform:none}}.fadeIn{-webkit-animation-name:fadeIn;animation-name:fadeIn}@media only screen and (max-width: 500px){h2{font-size:24px}p{font-size:18px}ul li{font-size:18px}footer ul{-webkit-justify-content:space-evenly;justify-content:space-evenly}.button{margin-bottom:16px;display:block;text-align:center}}

.work{display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;margin-bottom:60px}.work-card{-webkit-flex:0 1 50%;flex:0 1 50%;padding:16px 8px;box-sizing:border-box}.work-card .work-description{margin-top:-8px;padding:24px;box-shadow:rgba(149,157,165,0.2) 0px 8px 24px;transition:.3s all;border-bottom-left-radius:4px;border-bottom-right-radius:4px;background-color:#FFF}.work-card h3{margin:0;color:#333}.work-card img{border-top-left-radius:4px;border-top-right-radius:4px;transition:.3s all}.work-card span{margin-bottom:10px;display:block;font-size:16px;color:#333}.work-card small{font-size:14px;color:#333}.work-card:hover .work-description,.work-card:hover img{transition:.3s all;-webkit-transform:translateY(-4px);transform:translateY(-4px)}@media (max-width: 576px){.work{margin-top:30px}.work-card{-webkit-flex:auto;flex:auto;margin-bottom:0;padding:0 0 24px 0}.work-card .work-description{padding:16px}}

.about-img{margin:32px auto;display:block;border-radius:4px}.quote{margin-bottom:-76px;padding-bottom:72px;border-bottom:2px solid #FFF}.quote p{max-width:900px}.pipe{margin-right:64px;width:50px;float:right}@media (max-width: 576px){.pipe{display:none}}@media (min-width: 576px){.quote{padding-bottom:112px}}

nav{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;border-bottom:2px solid #fff}nav div a{color:#333;font-size:18px;font-weight:500;text-decoration:none}nav div a:hover{color:#2D84FF}nav ul{display:-webkit-flex;display:flex}nav ul li{padding:0 15px;list-style:none}nav ul li a{color:#333;font-size:18px;text-decoration:none;transition:all .2s}nav ul li a:hover{color:#2D84FF;cursor:pointer}

.posts .post{margin:15px 0;padding:20px;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;background:#fff;border-radius:8px;text-decoration:none;transition:.3s;border:1px solid #eee}.posts .post .date{display:block;font-size:16px}.posts .post:hover{-webkit-transform:translateY(-3px);transform:translateY(-3px);color:#2D84FF}

.post{margin-bottom:60px}.post .header-intro h2{margin-top:45px}.post .header-intro h4{margin-top:0}.post h2{margin-top:60px;margin-bottom:5px}.post p{margin-top:0;margin-bottom:30px}.post small{margin-top:16px;margin-bottom:32px;display:block;text-align:center}.post .sub-intro{display:-webkit-flex;display:flex}.post .sub-intro div{margin-right:32px}.post .sub-intro div h3{font-size:18px;margin-bottom:0;border-bottom:1px solid #eee}.post .sub-intro div p{margin:8px 0;line-height:1;font-size:16px}@media only screen and (max-width: 700px){.post .header-intro h4{font-size:18px}.sub-intro{-webkit-flex-direction:column;flex-direction:column}}

.home{padding-top:12px}.home .header{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between;font-family:Montserrat}.home .header .name{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.home .header .name img{margin-right:12px;width:80px;border-radius:50px}.home .header .nav-about a{padding:16px}.home .header .nav-about a:hover{background:#F2F2F2;border-radius:4px}.home .header h1{font-size:30px;line-height:1.2;display:inline-block}.home p{max-width:800px;line-height:1.6}@media (max-width: 576px){.home .header .name img{width:66px}.home .header h1{font-size:22px}.home .header a{font-size:18px}}

.work{display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;margin-bottom:60px}.case-study{-webkit-flex:0 1 50%;flex:0 1 50%;padding:12px 12px 0 0;box-sizing:border-box}.case-study .case-title{font-size:32px}.case-study .case-description{margin-top:-8px;padding:16px 24px 24px;box-shadow:rgba(149,157,165,0.2) 0px 8px 24px;transition:.3s all;background:#fff;border-bottom-left-radius:4px;border-bottom-right-radius:4px}.case-study h3{margin:0;color:#333}.case-study img{border-top-left-radius:4px;border-top-right-radius:4px;transition:.3s all}.case-study span{margin-bottom:10px;display:block;font-size:16px;color:#333}.case-study small{font-size:14px;color:#333}.case-card:hover .case-description,.case-card:hover img{transition:.3s all;-webkit-transform:translateY(-4px);transform:translateY(-4px)}@media only screen and (max-width: 576px){.case-study{-webkit-flex:auto;flex:auto;padding:12px 0}.case-study .case-description{padding:16px}}

footer{padding:12px 0;width:100%;font-family:'Montserrat', sans-serif}footer ul{padding-left:0;display:-webkit-flex;display:flex}footer ul li{margin-right:30px;list-style:none}footer ul li a:hover{color:#2D84FF}footer a{padding:16px;color:#333;text-decoration:none;transition:all .2s}footer a:hover{background:#f2f2f2;border-radius:4px}@media screen and (max-width: 576px){footer ul{padding-left:24px}footer a{padding:8px}}

