/*================================
          Navigation
================================*/
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #fff;

  div a {
    color: #333;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
  }

  div a:hover {
    color: #2D84FF;
  }

  ul  {
    display: flex;

    li {
      padding: 0 15px;
      list-style: none;

      a {
        color: #333;
        font-size: 18px;
        text-decoration: none;
        transition: all .2s;
      }

      a:hover {
        color: #2D84FF;
        cursor: pointer;
      }
    }
  }
}
