/*================================
             Work
================================*/
.work {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.work-card {
  flex: 0 50%;
  padding: 16px 8px;
  box-sizing: border-box;

  .work-description {
    margin-top: -8px;
    padding: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: .3s all;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #FFF;
  }

  h3 {
    margin: 0;
    color: #333;
  }

  img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    transition: .3s all;
  }

  span {
    margin-bottom: 10px;
    display: block;
    font-size: 16px;
    color: #333;
  }

  small {
    font-size: 14px;
    color: #333;
  }
}

.work-card:hover .work-description,
.work-card:hover img {
  transition: .3s all;
  transform: translateY(-4px);
}

// .addon {
//   background: #1E52A1;
// }

// .edco {
//   background: #F06D40;
// }

// .proline {
//   background: #C91417;
// }

// .sn {
//   background: #21355A;
// }

/* Smartphone view 📱*/
@media  (max-width : 576px) {
  .work {
    margin-top: 30px;
  }

  .work-card {
    .work-description {
      padding: 16px;
    }

    flex: auto;
    margin-bottom: 0;
    padding: 0 0 24px 0;
  }
}
