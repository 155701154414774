/*================================
             Work
================================*/
.work {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.case-study {
  flex: 0 50%;
  padding: 12px 12px 0 0;
  box-sizing: border-box;

  .case-title {
    font-size: 32px;
  }

  .case-description {
    margin-top: -8px;
    padding: 16px 24px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: .3s all;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  h3 {
    margin: 0;
    color: #333;
  }

  img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    transition: .3s all;
  }

  span {
    margin-bottom: 10px;
    display: block;
    font-size: 16px;
    color: #333;
  }

  small {
    font-size: 14px;
    color: #333;
  }
}

.case-card:hover .case-description,
.case-card:hover img {
  transition: .3s all;
  transform: translateY(-4px);
}

/* Smartphone view 📱*/
@media only screen and (max-width : 576px) {
  .case-study {
    flex: auto;
    padding: 12px 0;

    .case-description {
      padding: 16px;
    }
  }
}
