/*================================
            Footer
================================*/

footer {
  padding: 12px 0;
  width: 100%;
  font-family: 'Montserrat', sans-serif;

  ul {
    padding-left: 0;
    display: flex;

    li {
      margin-right: 30px;
      list-style: none;
    }

    li a:hover {
      color: #2D84FF;
    }
  }

  a {
    padding: 16px;
    color: #333;
    text-decoration: none;
    transition: all .2s;

  }

  a:hover {
    background: #f2f2f2;
    border-radius: 4px;
  }
}

/*======= 📱< 500px =======*/
@media screen and (max-width: 576px) {
  footer {
    ul {
      padding-left: 24px;
    }

    a {
      padding: 8px;
    }
  }
}
