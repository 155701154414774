/*================================
             About
================================*/
 .about-img {
   margin: 32px auto;
   display: block;
   border-radius: 4px;
 }

 .quote {
    margin-bottom: -76px;
    padding-bottom: 72px;
    border-bottom: 2px solid #FFF;

    p {
      max-width: 900px;
    }
 }

 .pipe {
   margin-right: 64px;
   width: 50px;
   float: right;
 }

 @media (max-width: 576px) {
    .pipe {
      display: none;
    }
 }

 @media (min-width: 576px) {
   .quote {
     padding-bottom: 112px;
   }
 }

