@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$blue: #2D84FF;
$blue-dark: #0067F9;
$orange: #FFA82D;

body {
  margin: 0 auto;
  max-width: 1400px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #252525;
  background: #FAFAFA;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  font-family: 'Montserrat', sans-serif;
}

.main-wrapper {
  position: relative;
  min-height: 100vh;
}

main {
  padding: 12px;
  line-height: 1.5;
}

h1, h2, h3, h4 {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 22px;
}

h4 {
  margin-bottom: 16px;
}

a {
  text-decoration: none;
  transition: .3s all;
  color: $blue;
}

a:hover {
  color: $blue-dark;
}

ul {
  margin-top: 16px;
  line-height: 1.8;
}

p {
  font-size: 20px;
  line-height: 1.8;
}

img {
  width: 100%;
}

.content img {
  margin: 16px 0 4px;
}

.block {
  margin-bottom: 24px;
  display: block;
  height: 4px;
  width: 40px;
  background: #2D84FF;
  border-radius: 4px;
}

.button {
  background-color: #2D84FF;
  color: #fff;
  padding: 16px 32px;
  border-radius: 4px;
  border-bottom: 4px solid $blue-dark;
  text-decoration: none;
}

.button:hover {
  opacity: .9;
  color: #fff;
  box-shadow: 0px 10px 40px -10px rgba(0, 64, 128, 0.2);
}

.max-1200 {
  margin: 0 auto;
  max-width: 1200px;
}

.max-1000 {
  margin: 0 auto;
  max-width: 1000px;
}

.max-800 {
  margin: 0 auto;
  max-width: 800px;
}

/*========================================
          Fade In
 ========================================*/
.animate {
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4%, 0);
    transform: translate3d(0, 4%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4%, 0);
    transform: translate3d(0, 4%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/*========================================
          Media Queries
 ========================================*/

/* Smartphone view 📱*/
@media only screen and (max-width : 500px) {
  h2 {
    font-size: 24px;
  }

  p {
    font-size: 18px;
  }

  ul li {
    font-size: 18px;
  }

  footer ul {
    justify-content: space-evenly;
  }

  .button {
    margin-bottom: 16px;;
    display: block;
    text-align: center;
  }
}
